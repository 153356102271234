<template>
  <form @submit.prevent="handleSubmit()">
    <div class="row">
      <div class="form-group col-md-4">
        <label for="">Policy Start Date</label
        ><input
          class="form-control"
          id="startdate"
          type="date"
          v-model="data.startDate"
          @input="addDays(data.startDate, data.duration)"
          required
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Policy Duration (In Days)</label
        ><input
          class="form-control"
          id="duration"
          type="number"
          min="1"
          max="365"
          v-model="data.duration"
          placeholder="Enter Duration"
          @input="addDays(data.startDate, data.duration)"
          required
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Policy End Date</label
        ><input
          class="form-control"
          id="duration"
          type="text"
          v-model="metaData.policyEnd"
          placeholder="Policy End Date"
          disabled
        />
      </div>

      <!-- <div class="form-group col-md-2">
        <label for="">Endorsement</label
        ><input
          class="form-control"
          id="endorsement"
          type="text"
          v-model="data.endorsement"
          placeholder="Enter Endorsement"
          required
        />
      </div>

      <div class="form-group col-md-2">
        <label for="">Endorsement Option</label
        ><select
          v-model="data.endorsementOptions"
          class="form-control"
          id="endorsementoption"
          required
        >
          <option value="">Select Endorsement Option</option>
          <option
            v-for="option in endorsementOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div> -->

      <div class="form-group col-md-3">
        <label for="">Voyage Type</label
        ><select
          @change="(this.data.premiumRate = ''), getRate()"
          v-model="data.voyageType"
          class="form-control"
          id="voyagetype"
          required
        >
          <option value="">Select Voyage Type</option>
          <option
            v-for="voyage in voyageTypes"
            :key="voyage.id"
            :value="voyage.name"
          >
            {{ voyage.name }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-3">
        <label for="">Parking Type</label
        ><select
          v-model="data.parkingType"
          class="form-control"
          id="parkingtype"
          required
        >
          <option value="">Select Parking Type</option>
          <option
            v-for="parkingType in parkingTypes"
            :key="parkingType.id"
            :value="parkingType.name"
          >
            {{ parkingType.name }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-3">
        <label for="">Conveyance</label
        ><select
          v-model="data.conveyance"
          class="form-control"
          id="conveyance"
          required
        >
          <option value="">Select Conveyance</option>
          <option
            v-for="conveyance in conveyances"
            :key="conveyance.id"
            :value="conveyance.name"
          >
            {{ conveyance.name }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-3">
        <label for="">Cover Scope</label
        ><select
          v-model="data.coverScope"
          @change="getRate(), calcPremium(), setParameters(data.coverScope)"
          class="form-control"
          id="coverscope"
          required
        >
          <option value="">Select Cover Scope</option>
          <option
            v-for="scope in coverScopes"
            :key="scope.id"
            :value="scope.id"
          >
            {{ scope.name }}
          </option>
        </select>
      </div>

      <!-- <div class="form-group col-md-2">
        <label for="">Certificate Type</label
        ><select
          v-model="data.certificateType"
          class="form-control"
          id="certificatetype"
          required
        >
          <option value="">Select Certificate Type</option>
          <option
            v-for="certificate in certificateTypes"
            :key="certificate"
            :value="certificate"
          >
            {{ certificate }}
          </option>
        </select>
      </div> -->

      <div class="form-group col-md-4">
        <label for="">Nature Of Cargo</label
        ><select
          v-model="data.natureofCargo"
          class="form-control"
          id="natureofcargo"
          required
        >
          <option value="">Select Nature Of Cargo</option>
          <option
            v-for="cargo in natureOfCargos"
            :key="cargo.id"
            :value="cargo.name"
          >
            {{ cargo.name }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-4">
        <label for="">Proformal Invoice Number</label
        ><input
          class="form-control"
          id="proformalinvoice"
          type="text"
          v-model="data.proformaInvoiceNo"
          placeholder="Enter Proformal Invoice Number"
          required
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Vessel</label
        ><input
          class="form-control"
          id="vessel"
          type="text"
          v-model="data.vessel"
          placeholder="Enter Vessel"
          required
        />
      </div>

      <div v-if="data.coverScope === 'MCI'" class="form-group col-md-2">
        <label for="">Type Of Cover</label
        ><select
          v-model="data.typeofCover"
          @change="getRate(), getAdditionalClauses(data.typeofCover)"
          class="form-control"
          id="typeofCover"
          required
        >
          <option value="">Select Type Of Cover</option>
          <option
            v-for="cover in coverTypes"
            :key="cover.id"
            :value="cover.name"
          >
            {{ cover.name }}
          </option>
        </select>
      </div>

      <div
        class="form-group"
        :class="[data.coverScope !== 'MCI' ? 'col-md-6' : 'col-md-5']"
      >
        <label for="">Shipment Going From</label
        ><input
          class="form-control"
          id="fromaddress"
          type="text"
          v-model="data.from"
          placeholder="Enter From Address"
          required
        />
      </div>

      <div
        class="form-group"
        :class="[data.coverScope !== 'MCI' ? 'col-md-6' : 'col-md-5']"
      >
        <label for="">Shipment Going To</label
        ><input
          class="form-control"
          id="toaddress"
          type="text"
          v-model="data.to"
          placeholder="Enter To Address"
          required
        />
      </div>

      <div v-if="data.coverScope === 'MOC'" class="form-group my-3 col-md-8">
        <label for="">Select Applicable Cover Types For Policy</label>
        <div class="row mx-1">
          <div
            v-for="type in coverTypes"
            :key="type.id"
            class="form-check my-2 col-md-3"
          >
            <input
              class="form-check-input"
              type="checkbox"
              v-model="data.addClauseOpenCover"
              :value="type.name"
              :id="'OC' + type.id"
              @change="
                getAdditionalClausesOpenCovers(), getRate(), calcPremium()
              "
            />
            <label class="form-check-label" :for="'OC' + type.id">
              {{ type.name }}
            </label>
          </div>
        </div>
      </div>

      <div v-if="data.coverScope === 'MOC'" class="form-group col-md-4">
        <label for="">Select Type Of Cover For First Certificate</label
        ><select
          v-model="data.typeofCover"
          class="form-control"
          id="typeofCover"
          required
        >
          <option value="">Select Type Of Cover</option>
          <option
            v-for="cover in data.addClauseOpenCover"
            :key="cover"
            :value="cover"
          >
            {{ cover }}
          </option>
        </select>
      </div>

      <div
        v-if="additionalClauses.length !== 0"
        class="form-group my-3 col-md-12"
      >
        <label for="">Select Additional Cover Clauses</label>
        <div class="row mx-1">
          <div
            v-for="clause in additionalClauses"
            :key="clause.id"
            class="form-check my-2 col-4"
          >
            <input
              class="form-check-input"
              type="checkbox"
              v-model="data.addClause"
              :value="clause.name"
              :id="'AC' + clause.id"
            />
            <label class="form-check-label" :for="'AC' + clause.id">
              {{ clause.name }}
            </label>
          </div>
        </div>
      </div>

      <div class="form-group mb-5 col-md-12">
        <label for="">Cargo Description (Detailed)</label
        ><textarea
          class="form-control"
          v-model="data.cargoDescription"
          id="cargodescription"
          rows="3"
          required
        ></textarea>
      </div>

      <div class="form-group col-md-2">
        <label for="">Currency</label
        ><select
          @change="setCurrency(metaData.currency), calcPremium()"
          v-model="metaData.currency"
          class="form-control"
          id="currency"
          required
        >
          <option :value="{}" v-if="data.exCurrency != ''">
            {{ data.exCurrency }}
            <!-- ({{ data.exRate }}) -->
          </option>
          <option v-else :value="{}">Select Currency</option>
          <option
            v-for="currency in currencyRates"
            :key="currency.id"
            :value="currency"
          >
            {{ currency.exCurrency }}
            <!-- ({{ data.exRate }}) -->
          </option>
        </select>
      </div>

      <div class="form-group col-md-2">
        <label for="">Exchange Rate</label
        ><input
          class="form-control"
          @input="calcPremium()"
          id="exrate"
          type="number"
          step="0.001"
          v-model="data.exRate"
          placeholder=""
          required
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Credit Note No</label
        ><input
          class="form-control"
          id="creditnote"
          type="text"
          v-model="data.brokersRefNo"
          placeholder="Enter Credit Note No"
          @change="uploadedFile && handleFileUpload(uploadedFile)"
          required
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Upload Credit Note Document</label
        ><input
          class="form-control"
          id="creditnoteupload"
          type="file"
          @change.prevent="handleFileUpload($event.target.files[0])"
          required
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Invoiced Value</label
        ><input
          class="form-control"
          id="invoicedValue"
          @input="calcPremium()"
          type="text"
          v-model="data.invoicedValue"
          placeholder="Enter Invoiced Value"
          required
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Naira Value</label
        ><input
          class="form-control"
          @input="calcPremium()"
          id="nairavalue"
          type="text"
          :value="metaData.nairaValue.toLocaleString()"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Sum Insured</label
        ><input
          class="form-control"
          @input="calcPremium()"
          id="suminsured"
          type="text"
          :value="data.sumInsured.toLocaleString()"
          placeholder="Enter Sum Insured"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Rate</label
        ><input
          class="form-control"
          @input="calcPremium()"
          id="premiumrate"
          type="number"
          step="0.001"
          v-model="data.premiumRate"
          placeholder="Enter Premium Rate"
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Premium</label
        ><input
          class="form-control"
          id="premium"
          type="text"
          :value="data.premium.toLocaleString()"
          placeholder="Enter Premium"
          disabled
        />
      </div>

      <div class="form-group col-md-4">
        <label for="">Loading (+ %)</label
        ><input
          class="form-control"
          id="excess"
          type="text"
          :value="metaData.percentLoading.toLocaleString()"
          disabled
        />
      </div>

      <div class="form-group col-md-12">
        <label for="">Excess</label
        ><input
          class="form-control"
          id="excess"
          type="text"
          v-model="data.excess"
          placeholder="Enter Excess"
          disabled
        />
      </div>
    </div>

    <div class="my-3">
      <button type="" @click.prevent="goBack()" class="btn btn-primary">
        Back
      </button>

      <button type="submit" class="btn btn-primary float-right">
        Save / Next
      </button>
    </div>
  </form>
</template>

<script>
//import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useMarineStore } from "@/stores/marine";
import { useAdminStore } from "@/stores/admin";
import { useMainStore } from "@/stores/main";

export default {
  name: "Buy Marine",
  //components: { AppLayout },
  data() {
    return {
      metaData: {
        currency: {},
        nairaValue: "",
        percentLoading: "",
        check: "",
        policyEnd: "",
        fileData: "",
      },
      minRate: 0.05,

      // minimumRates: [
      //     {
      //       voyage:'Port To Port',
      //       type:"ICC'A'",
      //       minRate: 0.15
      //     },
      //     {
      //       voyage:'Port To Port',
      //       type:"ICC'B'",
      //       minRate: 0.075
      //     },
      //     {
      //       voyage:'Port To Port',
      //       type:"ICC'C'",
      //       minRate: 0.050
      //     },
      //     {
      //       voyage:'Port To Port',
      //       type:"ICC(Air)",
      //       minRate: 0.15
      //     },
      //     {
      //       voyage:'Port To Port',
      //       type:"IFFC'A'",
      //       minRate: 0.150
      //     },
      //     {
      //       voyage:'Port To Port',
      //       type:"IFFC'C'",
      //       minRate: 0.10
      //     },
      //     {
      //       voyage:'Port To Port',
      //       type:"IBOC",
      //       minRate: 0.150
      //     },

      //     {
      //       voyage:'Port To Warehouse',
      //       type:"ICC'A'",
      //       minRate: 0.150
      //     },
      //     {
      //       voyage:'Port To Warehouse',
      //       type:"ICC'B'",
      //       minRate: 0.125
      //     },
      //     {
      //       voyage:'Port To Warehouse',
      //       type:"ICC'C'",
      //       minRate: 0.100
      //     },
      //     {
      //       voyage:'Port To Warehouse',
      //       type:"ICC(Air)",
      //       minRate: 0.150
      //     },
      //     {
      //       voyage:'Port To Warehouse',
      //       type:"IFFC'A'",
      //       minRate: 0.150
      //     },
      //     {
      //       voyage:'Port To Warehouse',
      //       type:"IFFC'C'",
      //       minRate: 0.100
      //     },
      //     {
      //       voyage:'Port To Warehouse',
      //       type:"IBOC",
      //       minRate: 0.150
      //     },

      //     ],

      data: {
        startDate: "",
        coverScope: "",
        duration: 0,
        transtype: "",
        conveyance: "",
        sumInsured: "",
        premium: 0,
        premiumRate: 0.05,
        typeofCover: "",
        endorsement: " ",
        from: "",
        to: "",
        exRate: "",
        exCurrency: "",
        cargoDescription: "",
        voyageType: "",
        parkingType: "",
        invoicedValue: "",
        excess: "N20,000 or 10% of claims (Whichever Is Higher)",
        certificateType: "",
        endorsementOptions: "New",
        proformaInvoiceNo: "",
        vessel: "",
        lienClause: "",
        natureofCargo: "",
        addClause: [],
        addClauseOpenCover: [],
        brokersRefNo: "",
        imageFile: "",
      },
    };
  },
  computed: {
    ...mapState(useMarineStore, [
      "loading",
      "componentLoading",
      "coverScopes",
      "endorsementOptions",
      "certificateTypes",
      "marineData",
      "marineMetaData",
      "currencyRates",
      "steps",
      "additionalClauses",
      "brokerRate",
    ]),
    ...mapState(useAdminStore, [
      "coverTypes",
      "natureOfCargos",
      "conveyances",
      "voyageTypes",
      "parkingTypes",
    ]),
    ...mapState(useMainStore, ["user"]),

    // rateValidation(){
    //   let minimum = 0
    //   if(this.data.coverScope === 'MCI'){
    //     this.minimumRates.forEach(rate=>{
    //       if(rate.type == this.data.typeofCover && rate.voyage == this.data.voyageType ) minimum = rate.minRate
    //     })

    //   }
    //    return minimum
    // }
  },

  methods: {
    ...mapActions(useMarineStore, [
      "getCoverScopes",
      "getEndorsementOptions",
      "getCertificateTypes",
      "getMarketers",
      "getCurrency",
      "incrementStep",
      "getBrokerRate",
      "getAdditionalClauses",
      "getAdditionalClausesOpenCover",
      "resetAdditionalClausesOpenCover",
      "saveStep",
      "warning",
    ]),
    ...mapActions(useAdminStore, [
      "getAllConveyances",
      "getAllNatureOfCargos",
      "getAllCoverTypes",
      "getAllParkingTypes",
      "getAllVoyageTypes",
    ]),

    handleFileUpload(file) {
      this.uploadedFile = file;
      let newFile = new File(
        [this.uploadedFile],
        `${
          this.user.brokerId + this.data.brokersRefNo + this.uploadedFile.name
        }`,
        {
          type: this.uploadedFile.type,
        }
      );

      this.data.imageFile = newFile.name;
      this.metaData.fileData = newFile;
    },

    addDays(date, days) {
      if (this.data.startDate !== "" && this.data.duration !== 0) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        this.metaData.policyEnd = `${result.getDate()}/${
          result.getMonth() + 1
        }/${result.getFullYear()}`;
      }
    },

    calcPremium() {
      if (this.data.invoicedValue !== "" && this.data.premiumRate !== 0) {
        this.metaData.nairaValue = this.data.invoicedValue * this.data.exRate;
        this.metaData.percentLoading = 0.1 * this.metaData.nairaValue;
        this.data.sumInsured =
          this.metaData.nairaValue + this.metaData.percentLoading;

        let calculatedPremium =
          (this.data.premiumRate / 100) * parseInt(this.data.sumInsured);
        if (this.data.coverScope == "MCI" && calculatedPremium < 5000) {
          this.data.premium = 5000;
        } else {
          this.data.premium = calculatedPremium;
        }
      }
    },

    async getRate() {
      if (this.data.coverScope === "MCI") {
        if (
          this.data.typeofCover != "" &&
          this.data.coverScope != "" &&
          this.data.voyageType != ""
        ) {
          await this.getBrokerRate(
            this.user.brokerId,
            this.data.typeofCover,
            this.data.coverScope,
            this.data.voyageType
          ).then(() => {
            if (this.brokerRate.name <= 0) this.minRate = 0.05;
            else this.minRate = this.brokerRate.name;
            this.calcPremium();
          });
        }
      }

      if (this.data.coverScope === "MOC") {
        if (
          this.data.addClauseOpenCover.length != 0 &&
          this.data.coverScope != "" &&
          this.data.voyageType != ""
        ) {
          let phRate = "0";

          for (let i = 0; i < this.data.addClauseOpenCover.length; i++) {
            await this.getBrokerRate(
              this.user.brokerId,
              this.data.addClauseOpenCover[i],
              this.data.coverScope,
              this.data.voyageType
            );

            if (this.brokerRate.name > phRate) phRate = this.brokerRate.name;
          }
          this.minRate = phRate;
          this.calcPremium();
        }
      }
    },

    getAdditionalClausesOpenCovers() {
      if (this.data.addClauseOpenCover.length === 0)
        this.resetAdditionalClausesOpenCover();
      else this.resetAdditionalClausesOpenCover();
      for (let i = 0; i < this.data.addClauseOpenCover.length; i++) {
        this.getAdditionalClausesOpenCover(this.data.addClauseOpenCover[i]);
      }
      // this.data.addClauseOpenCover.map((cover) =>
      //   this.getAdditionalClausesOpenCover(cover)
      // );
    },

    setParameters(cover) {
      if (cover == "MOC") {
        this.data.certificateType = "Open Cover";
        this.data.typeofCover = "";
        this.data.addClause = [];
        this.resetAdditionalClausesOpenCover();
      }
      if (cover == "MCI") {
        this.data.certificateType = "Single Transit";
        this.data.addClauseOpenCover = [];
        this.data.addClause = [];
        this.resetAdditionalClausesOpenCover();
      }
    },

    goBack() {
      this.incrementStep(1);
      this.$router.push(`/broker/buymarine/step-1`);
    },

    setCurrency(currency) {
      this.data.exCurrency = currency.exCurrency;
      this.data.exRate = parseFloat(currency.rates);
    },

    handleSubmit() {
      if (this.data.premiumRate < this.minRate) {
        this.warning(
          "The Premium Rate Is Below The Approved Rate On This Portal, Please Enter A Valid Rate."
        );
        return;
      }
      this.saveStep(this.data, this.metaData);
      this.incrementStep(3);
      this.$router.push(`/broker/buymarine/step-3`);
    },

    disableDate() {
      setTimeout(function () {
        var dtToday = new Date();

        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10) month = "0" + month.toString();
        if (day < 10) day = "0" + day.toString();

        let maxDate = year + "-" + month + "-" + day;
        const dob = document.getElementById("startdate");
        dob.setAttribute("min", maxDate);
      }, 1000);
    },
  },

  created() {
    if (this.steps < 2) {
      this.$router.push(`/broker/buymarine/step-${this.steps}`);
    }
    this.data = { ...this.data, ...this.marineData };
    this.metaData = { ...this.metaData, ...this.marineMetaData };
    this.getCoverScopes();
    this.getEndorsementOptions();
    this.getCertificateTypes();
    this.getCurrency();
    this.getAllConveyances();
    this.getAllCoverTypes();
    this.getAllNatureOfCargos();
    this.getAllParkingTypes();
    this.getAllVoyageTypes();
  },

  mounted() {
    this.disableDate();
  },
};
</script>
